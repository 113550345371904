import React, { Suspense, lazy, useEffect } from 'react';
import './App.css';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loading from '../src/components/loading';

// auth
const LandingPage = lazy(() => import('./view/LandingPage.js'));
const CertificatePage = lazy(() => import('./view/CertificatePage.js'));

function App() {
  useEffect(() => {
    // Function to clear 'c3offline' cache storage
    const clearCacheStorage = async () => {
      if ('caches' in window) {
        try {
          const cacheNames = await caches.keys();
          const cacheToDelete = cacheNames.find((name) =>
            name.startsWith('c3offline-')
          );
          if (cacheToDelete) {
            await caches.delete(cacheToDelete);
            console.log(`${cacheToDelete} cache storage cleared`);
          }
        } catch (error) {
          console.error('Error clearing cache storage:', error);
        }
      }
    };

    // Clear cache storage when the app loads
    clearCacheStorage();
  }, []); // The empty dependency array ensures this runs only on initial load

  return (
    <div className="app-wrapper">
      <div className="App">
        <Suspense fallback={<Loading />}>
          <Router>
            <Routes>
              <Route exact path="/" element={<LandingPage />} />
              <Route exact path="/game" element={<CertificatePage />} />
            </Routes>
          </Router>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
